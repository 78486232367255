import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {MatDrawer, MatDrawerMode} from '@angular/material/sidenav/drawer';
import {Stream} from '@apirtc/apirtc';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {NGXLogger} from 'ngx-logger';
import {Subject, Subscription, lastValueFrom, takeUntil, timer} from 'rxjs';
import {AppLang, AppState} from 'src/app/core/models/app-state.models';
import {DefaultMediaDevicesID, StreamConstraints} from 'src/app/core/models/media-devices.models';
import {User} from 'src/app/core/models/user.models';
import {ApirtcConversationService} from 'src/app/core/services/apirtc/conversation.service';
import {AppStreamsService} from 'src/app/core/services/streams.service';
import {appConfigActions, consultationActions, mediaDeviceActions} from 'src/app/store/actions';
import {
  appConfigSelectors,
  appSelectors,
  consultationSelectors,
  onlineParticipantsSelectors,
  sessionSelectors,
  userMediaDevicesSelectors
} from 'src/app/store/selectors';
import {MediaDrawerComponent} from '../media-drawer/media-drawer.component';
import {OnlineParticipants} from 'src/app/core/models/participants.models';
import {ChatService} from 'src/app/core/services/chat.service';
import {ConsultationConstraints} from 'src/app/core/models/consultation.models';
import {HumanNamePipe} from '../../pipes/name.pipe';
import {requestFileSharing} from 'src/app/store/actions/consultation.actions';
import {AppDialogData} from 'src/app/core/models/app-dialog.model';
import {MatDialog} from '@angular/material/dialog';
import {AppDialogComponent} from '../dialog/app-dialog.component';

@Component({
  selector: 'app-consultation',
  templateUrl: 'consultation.component.html',
  styleUrls: ['consultation.component.scss']
})
export class AppConsultationComponent implements OnInit, AfterViewInit, OnDestroy {
  private onDestroyed$ = new Subject();

  //Do not remove this
  private notificationSound = new Audio('../../../../assets/sounds/notification.mp3');

  @ViewChild('matDrawer') matDrawer: MatDrawer;
  @ViewChild(MediaDrawerComponent) mediaDrawerComp: MediaDrawerComponent;

  //   Other person who is in the call
  @Input() oppositeParticipant: User;
  @Input() showFullScreenCtrl = false;
  @Output() endConsultation = new EventEmitter<boolean>();
  @Output() toggleFullScreen = new EventEmitter<boolean>();
  @Output() isFullScreenChange = new EventEmitter();

  mode: MatDrawerMode;
  drawerState: boolean = false;
  showChat: boolean;
  showMediaControls: boolean;
  showScreenshotBtn = true;

  isMobile = false;
  isFullScreen: boolean = true;
  lang: AppLang;

  private onlineParticipants: OnlineParticipants = {};

  private localStream: Stream;
  private remoteStreamId: string | number;
  private timerStarted = false;
  private timer$: Subscription;
  counter = 0;

  localStreamConstraints: StreamConstraints;
  isWebview = false;
  isAndroid = false;

  newMessagesAvailable = false;
  waitingForRemote: boolean;
  isRemoteMediaEnabled: boolean = false;
  defaultMedia: DefaultMediaDevicesID;

  constructor(
    private logger: NGXLogger,
    private store: Store<AppState>,
    private streamService: AppStreamsService,
    private conversationService: ApirtcConversationService,
    private chatService: ChatService,
    private translate: TranslateService,
    private namePipe: HumanNamePipe,
    private dialog: MatDialog
  ) {
    this.store
      .select(onlineParticipantsSelectors.selectOnlineParticipants)
      .pipe(takeUntil(this.onDestroyed$))
      .subscribe((o) => {
        this.onlineParticipants = o;
        const userSessionIDs = this.onlineParticipants[this.oppositeParticipant?.user_uid];
        if (userSessionIDs) {
          this.chatService.requestFileShareStatus(Object.keys(userSessionIDs));
        }
      });

    this.store
      .select(sessionSelectors.selectLang)
      .pipe(takeUntil(this.onDestroyed$))
      .subscribe((r) => (this.lang = r));

    this.store
      .select(appSelectors.appConfigSelectors.selectDeviceForm)
      .pipe(takeUntil(this.onDestroyed$))
      .subscribe((d) => (this.isMobile = d));

    this.store
      .select(consultationSelectors.selectConsultation)
      .pipe(takeUntil(this.onDestroyed$))
      .subscribe((r) => {
        this.remoteStreamId =
          streamService?.remoteStreams[this.oppositeParticipant?.user_uid]?.streamId;
        this.updateConsultationStates(r);
        if (r?.startTime && !this.timerStarted) {
          this.startCounter(r.startTime);
        } else if (r?.startTime) {
          this.timerStarted = true;
        }
      });

    this.store
      .select(userMediaDevicesSelectors.selectMediaConstraints)
      .pipe(takeUntil(this.onDestroyed$))
      .subscribe((r) => (this.localStreamConstraints = r));

    this.store
      .select(appConfigSelectors.selectWebviewStatus)
      .pipe(takeUntil(this.onDestroyed$))
      .subscribe((r) => (this.isWebview = r));

    this.store
      .select(appConfigSelectors.selectAndroidStatus)
      .pipe(takeUntil(this.onDestroyed$))
      .subscribe((r) => (this.isAndroid = r));

    this.store
      .select(userMediaDevicesSelectors.selectDefaultDevices)
      .pipe(takeUntil(this.onDestroyed$))
      .subscribe((r) => (this.defaultMedia = r));
  }

  ngOnInit() {
    if (this.oppositeParticipant?.is_moderator) {
      this.showScreenshotBtn = false;
    }
    // this.initLocalStream();
  }

  ngAfterViewInit(): void {
    this.enableFullScreen();
  }

  private startCounter(startTime: string) {
    if (this.timerStarted) {
      return;
    }
    const startDue = startTime ? new Date(startTime) : 1000;
    this.timerStarted = true;
    this.timer$ = timer(startDue, 1000)
      .pipe(takeUntil(this.onDestroyed$))
      .subscribe((n) => (this.counter = n));
  }

  private async updateConsultationStates(c: ConsultationConstraints) {
    this.waitingForRemote = c?.waitingForRemote;
    this.newMessagesAvailable = c?.newMessages;
    const requestedSnapshot = c?.requestedSnapshot;
    const requestedFileSharing = c?.requestedFileSharing;
    const oppositeParticipantUID = this.oppositeParticipant?.user_uid;

    if (c?.participants) {
      const oppostieParticipantConstraints = c?.participants[oppositeParticipantUID];

      if (this.remoteStreamId) {
        this.isRemoteMediaEnabled = oppostieParticipantConstraints?.streams
          ? oppostieParticipantConstraints?.streams[this.remoteStreamId]?.video
          : false;
      }
    }

    if (requestedSnapshot) {
      const userSessionIds = this.onlineParticipants[oppositeParticipantUID];
      this.chatService.requestSnapshot(
        Object.keys(userSessionIds),
        this.namePipe.transform(this.oppositeParticipant, 'allName')
      );
      this.store.dispatch(consultationActions.requestSnapshot({requestedSnapshot: false}));
    }

    if (requestedFileSharing) {
      this.store.dispatch(consultationActions.requestFileSharing({requestedFileSharing: false}));

      const userSessionIds = this.onlineParticipants[oppositeParticipantUID];
      const dialogData: AppDialogData = {
        text: this.translate.instant('CONSULTATION.file_sharing_request', {
          name: this.namePipe.transform(this.oppositeParticipant, 'allName')
        }),
        showConfirmButton: true,
        confirmButtonText: this.translate.instant('COMMON.agree'),
        confirmButtonStyle: 'alm-btn-primary',
        showCancelButton: true,
        cancelButtonText: this.translate.instant('COMMON.deny'),
        cancelButtonStyle: 'alm-btn-danger'
      };

      const dialogRef = this.dialog.open(AppDialogComponent, {
        data: dialogData,
        ariaLabel: this.translate.instant('CONSULTATION.file_request_dialog_label')
      });

      await lastValueFrom(dialogRef.afterClosed()).then(async (r) => {
        if (r) {
          this.chatService.sendFileShareRequestAllow(Object.keys(userSessionIds));
        } else {
          this.chatService.sendFileShareRequestDeny(Object.keys(userSessionIds));
        }
      });
    }
  }

  openChatDrawer() {
    this.mode = 'side';
    this.showChat = true;
    this.showMediaControls = false;
    this.drawerState = !this.drawerState;
    this.store.dispatch(consultationActions.clearNewMessages());
    if (this.isMobile) {
      this.mode = 'over';
    }
  }

  openMediaDrawer() {
    this.mode = 'over';
    this.showChat = false;
    this.showMediaControls = true;
    this.drawerState = true;
    this.mediaDrawerComp?.updatePreviewStream();
  }

  clearLocalMediaDeviceTesting() {
    this.mediaDrawerComp?.releasePreviewStream();
  }

  closeDrawer() {
    this.store.dispatch(appConfigActions.messageWindowOpen({value: false}));
    this.matDrawer.close();
  }

  async hangup() {
    if (this.oppositeParticipant?.user_uid) {
      const userSessionIds = this.onlineParticipants[this.oppositeParticipant.user_uid];
      if (userSessionIds) {
        Object.keys(userSessionIds).forEach((i) => {
          this.chatService.endUserDataChannel(i);
        });
      }
    }
    this.endConsultation.emit(true);
  }

  drawerStateOpenChange(e: boolean) {
    if (e && this.showChat) {
      this.store.dispatch(appConfigActions.messageWindowOpen({value: true}));
    } else {
      this.store.dispatch(appConfigActions.messageWindowOpen({value: false}));
    }
  }

  // private async initLocalStream() {
  //   this.localStream = await this.streamService.createOrGetLocalStream();
  // }

  toggleVideoCam() {
    this.streamService.toggleLocalStreamVideo();
  }

  toggleAudio() {
    this.streamService.toggleLocalStreamAudio();
  }

  enableFullScreen() {
    this.isFullScreen = true;
    this.toggleFullScreen.emit(this.isFullScreen);
  }

  disableFullScreen() {
    this.isFullScreen = false;
    this.toggleFullScreen.emit(this.isFullScreen);
    this.closeDrawer();
  }

  async enableScreensharing() {
    try {
      const screenshare = await this.streamService.changeLocalStreamToScreenshare();
      if (!screenshare) {
        this.logger.error('Failed to create screensharing stream');
      }
      // TODO: Change this to replace first and release existing stream instead
      screenshare.screenStream.on('stopped', this.disableScreensharing.bind(this));
      this.conversationService.replaceCurrentStream(screenshare.stream);
    } catch (error) {}
  }

  async disableScreensharing() {
    const stream = await this.streamService.changeScreenshareToLocalStream();
    this.conversationService.replaceCurrentStream(stream);
  }

  requestSnapshot() {
    const userSessionIDs = this.onlineParticipants[this.oppositeParticipant?.user_uid];
    const userIDs = Object.keys(userSessionIDs);
    this.chatService.sendSnapRequest(userIDs);
  }

  async fileListRefreshed(e: boolean) {
    if (this.isAndroid) {
      this.store.dispatch(
        mediaDeviceActions.setDefaultMediaDevices({
          defaultDevices: this.defaultMedia
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.streamService?.screenShareStream?.release();
    this.onDestroyed$.next(null);
    this.onDestroyed$.complete();
  }
}
