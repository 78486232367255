import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {lastValueFrom} from 'rxjs';
import {NGXLogger} from 'ngx-logger';
import {TranslateService} from '@ngx-translate/core';
import {chatActions, consultationActions} from 'src/app/store/actions';
import {
  ApirtcDataChannelDataReceived,
  ApirtcDataChannelStatus,
  ApirtcTransferInformation,
  DataChannelChatAckEvent,
  DataChannelData,
  DataChannelDataEvents,
  DataChannelEvents,
  DataChannelFileEvent,
  DataChannelTextEvent,
  ExtendedDataChannel,
  FileShareStatusEvent
} from '../models/apirtc.models';
import {AppState} from '../models/app-state.models';
import {ChatMessageStatus, ChatType, KnownImageTypes} from '../models/chat.models';
import {AppErrors} from '../models/errors.models';
import {ApirtcSessionService} from './apirtc/session.service';
import {AppSnackBarService} from './snackbar.service';
import {ChatNotificationsService} from './notifications.service';
import {AppDialogData} from '../models/app-dialog.model';
import {MatDialog} from '@angular/material/dialog';
import {AppDialogComponent} from 'src/app/shared/components/dialog/app-dialog.component';

@Injectable()
export class ChatService {
  private userDataChannels: {
    [apiRTCUserIds: string]: ExtendedDataChannel;
  } = {};

  constructor(
    private logger: NGXLogger,
    private store: Store<AppState>,
    private sessionService: ApirtcSessionService,
    private snackBarService: AppSnackBarService,
    private translate: TranslateService,
    private notifications: ChatNotificationsService,
    private dialog: MatDialog
  ) {}

  async sendText(contactIDs: string[], message: string) {
    try {
      message = message.trim();
      const textMessage: DataChannelData<DataChannelTextEvent> = {
        event: DataChannelDataEvents.chat,
        msg: message,
        chatType: ChatType.text
      };

      this.sendInChat(contactIDs, textMessage);
    } catch (error) {
      this.logger.error('Failed to send text in chat', error);
      throw new Error(AppErrors.Failed_to_send_text);
    }
  }

  async sendFile(contactIDs: string[], file: File) {
    try {
      if (!file) {
        return;
      }
      const base64 = (await this.fileToBase64(file).catch((error) => {
        throw new Error(AppErrors.Failed_to_convert_file_base64);
      })) as string;

      let chatType = ChatType.file;
      if (file.type in KnownImageTypes) {
        chatType = ChatType.image;
      }

      const fileMessage: DataChannelData<DataChannelFileEvent> = {
        event: DataChannelDataEvents.file,
        fileName: file.name,
        base64: base64,
        fileSize: file.size,
        fileType: file.type,
        chatType: chatType
      };
      this.sendInChat(contactIDs, fileMessage);
    } catch (error) {
      this.logger.error('Failed to send file in chat', error);
      throw new Error(AppErrors.Failed_to_send_file);
    }
  }

  async sendFileShareStatus(contactIDs: string[], {accepted, canShare}: FileShareStatusEvent) {
    try {
      const request: DataChannelData<any> = {
        event: DataChannelDataEvents.file_share_status_response,
        chatType: ChatType.file_request,
        status: {
          accepted,
          canShare
        }
      };
      this.sendInChat(contactIDs, request);
    } catch (error) {
      this.logger.error('Failed to send file request status', error);
      throw new Error(AppErrors.Failed_to_send_file_share_status);
    }
  }

  async requestFileShareStatus(contactIDs: string[]) {
    try {
      const request: DataChannelData<any> = {
        event: DataChannelDataEvents.file_share_status_request,
        chatType: ChatType.file_request
      };
      this.sendInChat(contactIDs, request);
    } catch (error) {
      this.logger.error('Failed to send file request status', error);
      throw new Error(AppErrors.Failed_to_request_file_share_status);
    }
  }

  async sendFileShareRequest(contactIDs: string[]) {
    try {
      this.store.dispatch(consultationActions.fileSharingRequestsIncrement());
      const request: DataChannelData<any> = {
        event: DataChannelDataEvents.file_share_request,
        chatType: ChatType.file_request
      };
      this.sendInChat(contactIDs, request);
      this.snackBarService.createSnackBar(
        this.translate.instant('CHAT_SERVICE.file_share_request_sent'),
        ''
      );
    } catch (error) {
      this.logger.error('Failed to send file request', error);
      throw new Error(AppErrors.Failed_to_send_file_share_request);
    }
  }
  async sendFileShareRequestDeny(contactIDs: string[]) {
    try {
      this.store.dispatch(consultationActions.filesDenied());
      const request: DataChannelData<any> = {
        event: DataChannelDataEvents.file_share_response_deny,
        chatType: ChatType.file_request
      };
      this.sendInChat(contactIDs, request);
      this.snackBarService.createSnackBar(
        this.translate.instant('CHAT_SERVICE.file_share_request_denied'),
        ''
      );
    } catch (error) {
      this.logger.error('Failed to send file request', error);
      throw new Error(AppErrors.Failed_to_send_file_share_request_deny);
    }
  }
  async sendFileShareRequestAllow(contactIDs: string[]) {
    try {
      this.store.dispatch(consultationActions.filesAccepted());
      const request: DataChannelData<any> = {
        event: DataChannelDataEvents.file_share_response_allow,
        chatType: ChatType.file_request
      };
      this.sendInChat(contactIDs, request);
      this.snackBarService.createSnackBar(
        this.translate.instant('CHAT_SERVICE.file_share_request_allowed'),
        ''
      );
    } catch (error) {
      this.logger.error('Failed to send file request', error);
      throw new Error(AppErrors.Failed_to_send_file_share_request_allow);
    }
  }
  async sendSnapRequest(contactIDs: string[]) {
    try {
      const snapRequest: DataChannelData<any> = {
        event: DataChannelDataEvents.snapshot_request,
        chatType: ChatType.snapshot
      };
      this.sendInChat(contactIDs, snapRequest);
      this.snackBarService.createSnackBar(
        this.translate.instant('CHAT_SERVICE.remote_snapshot_request'),
        ''
      );
    } catch (error) {
      this.logger.error('Failed to send snapshot request', error);
      throw new Error(AppErrors.Failed_to_send_snap_request);
    }
  }

  async sendSnapResponse(contactIDs: string[]) {
    try {
      const snapResponse: DataChannelData<any> = {
        event: DataChannelDataEvents.snapshot_response_deny,
        chatType: ChatType.snapshot
      };
      this.sendInChat(contactIDs, snapResponse);
    } catch (error) {
      this.logger.error('Failed to send snapshot denied response', error);
      throw new Error(AppErrors.Failed_to_send_snap_request);
    }
  }

  private async sendInChat(
    contactIDs: string[],
    data: DataChannelData<DataChannelTextEvent | DataChannelFileEvent>
  ): Promise<{
    dataChannel: ExtendedDataChannel;
    messageId: string;
  }> {
    if (contactIDs.length === 0) {
      return;
    }
    for (let c = 0; c < contactIDs.length; c++) {
      const id = contactIDs[c];
      let dataChannel: ExtendedDataChannel;
      try {
        dataChannel = this.getUserDataChannel(id);
      } catch (error) {
        this.logger.error('Failed to create/get data channel', error);
        throw error;
      }

      if (
        dataChannel.getStatus().toString() === ApirtcDataChannelStatus.DATACHANNEL_STATUS_ONGOING
      ) {
        return await this.sendInDataChannel({dataChannel, data});
      }

      dataChannel
        .on(DataChannelEvents.opened, (e) => {
          this.logger.debug('DataChannel:', DataChannelEvents.opened, e);

          dataChannel.getStatus().toString() === ApirtcDataChannelStatus.DATACHANNEL_STATUS_ONGOING
            ? this.sendInDataChannel({dataChannel, data})
            : undefined;
        })
        .on(DataChannelEvents.transferComplete, (t: ApirtcTransferInformation) => {
          this.logger.debug('DataChannel:', DataChannelEvents.transferComplete, t);
          this.store.dispatch(
            chatActions.messageSent({
              messageId: t.uuid,
              userUUID: dataChannel.getContact().getUserData().get('uuid')
            })
          );
        })
        .on(DataChannelEvents.dataReceived, (t: ApirtcDataChannelDataReceived) => {
          this.logger.debug('DataChannel:', DataChannelEvents.dataReceived, t);
          const data: DataChannelData<DataChannelChatAckEvent> = JSON.parse(t.data);
          if (data.event === DataChannelDataEvents.ackRecivied) {
            this.store.dispatch(
              chatActions.messageAck({
                messageId: data?.messageId,
                userUUID: dataChannel.getContact().getUserData().get('uuid'),
                readAt: data?.readAt
              })
            );
          } else {
            this.logger.debug('DataChannel: unknown data event received', data);
          }
        })
        .on(DataChannelEvents.accepted, (a) => {
          this.logger.debug(DataChannelEvents.accepted, 'DataChannel', dataChannel);
        })
        .on(DataChannelEvents.response, (...e) =>
          this.logger.debug('(not implemented) DataChannel:', DataChannelEvents.response, e)
        )
        .on(DataChannelEvents.declined, (...e) =>
          this.logger.debug('(not implemented) DataChannel:', DataChannelEvents.declined, e)
        )
        .on(DataChannelEvents.closed, (...e) =>
          this.logger.debug('(not implemented) DataChannel:', DataChannelEvents.closed, e)
        )
        .on(DataChannelEvents.ended, (...e) =>
          this.logger.debug('(not implemented) DataChannel:', DataChannelEvents.ended, e)
        )
        .on(DataChannelEvents.transferProgress, (i: ApirtcTransferInformation) => {
          this.store.dispatch(
            chatActions.transferProgress({
              messageId: i?.uuid,
              userUUID: dataChannel.getContact().getUserData().get('uuid'),
              progress: i.percentage
            })
          );
        })
        .on(DataChannelEvents.error, (...e) =>
          this.logger.debug('(not implemented) DataChannel:', DataChannelEvents.error, e)
        );
    }
  }

  private async sendInDataChannel({
    dataChannel,
    data
  }: {
    dataChannel: ExtendedDataChannel;
    data: DataChannelData<DataChannelTextEvent | DataChannelFileEvent>;
  }): Promise<{
    dataChannel: ExtendedDataChannel;
    messageId: string;
  }> {
    try {
      const messageId = (await dataChannel.sendData(JSON.stringify(data))).toString();

      switch (data.chatType) {
        case ChatType.text:
          data = data as DataChannelData<DataChannelTextEvent>;
          this.store.dispatch(
            chatActions.createChatMessage({
              chatMessage: {
                apirtcSessionId: dataChannel.getContact().getId(),
                id: messageId,
                msg: data?.msg,
                readAt: null,
                sentAt: new Date().toISOString(),
                status: ChatMessageStatus.sending,
                isRemote: false,
                chatType: data.chatType
              },
              userUUID: dataChannel?.getContact()?.getUserData()?.get('uuid')
            })
          );
          this.notifications.playChatNotificationSound();
          break;
        case ChatType.image:
        case ChatType.file:
          data = data as DataChannelData<DataChannelFileEvent>;
          let chatType = ChatType.file;
          this.store.dispatch(
            chatActions.createChatMessage({
              chatMessage: {
                apirtcSessionId: dataChannel.getContact().getId(),
                id: messageId,
                msg: data.base64,
                readAt: null,
                sentAt: new Date().toISOString(),
                status: ChatMessageStatus.sending,
                isRemote: false,
                chatType: data.chatType,

                fileName: data.fileName,
                fileSize: data.fileSize,
                fileType: data.fileType,
                transferProgress: 0
              },
              userUUID: dataChannel?.getContact()?.getUserData()?.get('uuid')
            })
          );
          this.notifications.playChatNotificationSound();
          break;
        case ChatType.snapshot:
          break;
        default:
          break;
      }

      this.logger.debug('Message sent successfully', messageId, data);
      return {
        dataChannel: dataChannel,
        messageId: messageId
      };
    } catch (error) {
      this.logger.error('Failed to send the message', data, error);
    }
  }

  getUserDataChannel(id): ExtendedDataChannel {
    if (this.userDataChannels[id]) {
      const dc = this.userDataChannels[id];
      if (dc.getStatus().toString() === ApirtcDataChannelStatus.DATACHANNEL_STATUS_ONGOING) {
        return dc;
      }
    }
    const session = this.sessionService.session;
    const destinationContact = session.getOrCreateContact(id);
    if (!destinationContact.isOnline) {
      throw new Error(AppErrors.Contact_offline);
    }
    this.userDataChannels[id] = destinationContact.startDataChannel() as ExtendedDataChannel;
    this.logger.debug('Started new DataChannel with', id);
    return this.userDataChannels[id];
  }

  endUserDataChannel(id) {
    if (this.userDataChannels) {
      delete this.userDataChannels[id];
    }
  }

  async requestSnapshot(contactIDs: string[], requestedBy) {
    const dialogData: AppDialogData = {
      text: this.translate.instant('CHAT_SERVICE.private_screenshot_request', {
        name: requestedBy
      }),
      showConfirmButton: true,
      confirmButtonText: this.translate.instant('CHAT_SERVICE.btn_agree_send'),
      confirmButtonStyle: 'alm-btn-primary',
      showCancelButton: true,
      cancelButtonText: this.translate.instant('CHAT_SERVICE.btn_deny'),
      cancelButtonStyle: 'alm-btn-danger'
    };

    const dialogRef = this.dialog.open(AppDialogComponent, {
      data: dialogData,
      ariaLabel: this.translate.instant('CHAT_SERVICE.screenshot_request_dialog_label')
    });

    await lastValueFrom(dialogRef.afterClosed()).then(async (r) => {
      if (r) {
        const snapFile = await this.takeLocalSnapsot();
        this.sendFile(contactIDs, snapFile);
      } else {
        this.sendSnapResponse(contactIDs);
      }
    });
  }

  private async takeLocalSnapsot() {
    const canvas: any = document.querySelector('#localStreamCanvas');
    const video: any = document.querySelector('#localStreamVideo');

    canvas.height = String(video.videoHeight);
    canvas.width = String(video.videoWidth);
    canvas.getContext('2d').drawImage(video, 0, 0);

    const dataUrl = canvas.toDataURL('image/jpeg');
    const blob: Blob = await (await fetch(dataUrl)).blob();

    return new File([blob], 'Screenshot', {type: 'image/jpeg'});
  }

  private fileToBase64(f: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(f);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
}
